import React, { useEffect, useState } from "react"
import { Badge, Col, FloatingLabel, Form, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import styles from './style.module.css';
import { getRandomInt, HttpNoData } from "../../../Core";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import { FaRubleSign } from "react-icons/fa";

export default function Product({ product, jwt, isUse, onSum, onWeight, onUpdate }) {

    // arrays
    const [currentAdditions, setCurrentAddition] = useState([]);
    const [currentRangeSizes, setCurrentRangeSizes] = useState([]);
    const [currentRangeThickness, setCurrentRangeThickness] = useState([]);

    // count property
    const [selfCount, setSelfCount] = useState(1);
    const [priceSelf, setPriceSelf] = useState(0);

    // error toast
    const [errorToast, setErrorToast] = useState();

    // thickness, weight and size
    const [thickness, setThickness] = useState(product.standardThickness);
    const [size, setSize] = useState(product.standardSize);
    const [weight, setWeight] = useState(0);

    // value or percent discount
    const [discountsPercent, setDiscountsPercent] = useState([]);
    const [discountsValue, setDiscountsValue] = useState([]);

    // using in fake id key in list (map)
    const [percentId, setPercentId] = useState(1);
    const [valueId, setValueId] = useState(1);

    // prices
    const [sumSize, setSumSize] = useState(0);
    const [nominalPrice, setNominalPrice] = useState(0);
    const [sumAddition, setSumAddition] = useState(0);
    const [priceEnd, setPriceEnd] = useState(0);
    const [priceSumCount, setPriceSumCount] = useState(0);


    // did mount
    useEffect(() => {

        async function didMount() {
            const sizes = await HttpNoData(`/api/v1/calculateOrderCost/productRangeSizes/${product.id}`, 'GET', jwt);

            if (sizes.statusSuccessful)
                setCurrentRangeSizes(sizes.data?.sort((a, b) => a.size - b.size) ?? []);

            const thicknessResponse = await HttpNoData(`/api/v1/calculateOrderCost/productRangeThickness/${product.id}`, 'GET', jwt);

            if (thicknessResponse.statusSuccessful)
                setCurrentRangeThickness(thicknessResponse.data?.sort((a, b) => a.thickness - b.thickness) ?? []);

            const additions = await HttpNoData(`/api/v1/calculateOrderCost/products/additions/${product.id}`, 'GET', jwt);

            if (additions.statusSuccessful)
                setCurrentAddition(additions.data ?? []);
        }

        didMount();

    }, []);

    // math price (sumSize)
    useEffect(() => {

        if (!Number(size)) {
            onSum(product, 0);
            return;
        }

        if (size < product.minSize || size > product.maxSize) {
            setErrorToast({ key: 'outRange', message: `К сожалению вы вышли за границы продукции. \n От ${product.minSize}мм до ${product.maxSize}мм`, variant: 'danger' });
            return;
        }

        if (errorToast && errorToast.key == 'outRange')
            setErrorToast();

        if (currentRangeSizes.length <= 1) {
            setSumSize(currentRangeSizes[0]?.price ?? 0);
            return;
        }

        for (let i = 0; i < currentRangeSizes.length; i++) {
            const element = currentRangeSizes[i];

            if (element.size < size && currentRangeSizes.length - (i + 1) > 0)
                continue;

            if (element.size == size) {
                setSumSize(element.price);
                break;
            }

            let minPrice = i == 0 ? 0 : currentRangeSizes[i - 1].price;
            let maxPrice = element.price;
            let minSize = i == 0 ? 0 : currentRangeSizes[i - 1].size;
            let maxSize = element.size;

            setSumSize((maxPrice - minPrice) / (maxSize - minSize) * size);
            break;
        }

    }, [size, currentRangeSizes]);

    useEffect(() => {

        if(product.standardThickness == thickness)
        {
            const nominalPrice = +(+sumSize).toFixed(0);
            setNominalPrice(nominalPrice);
            return;
        }

        let upperPriceOnThickness = 0;
        for (let i = 0; i < currentRangeThickness.length; i++) {
            const element = currentRangeThickness[i];

            if (element.thickness < thickness && currentRangeThickness.length - (i + 1) > 0)
                continue;

            upperPriceOnThickness = element.percent / 100;
            break;
        }

        const nominalPrice = +(+sumSize + (+sumSize * upperPriceOnThickness)).toFixed(0);
        setNominalPrice(nominalPrice);
    }, [thickness, sumSize]);

    useEffect(() => {

        onUpdate({
            ...product,
            additions: currentAdditions.filter(f => f.isUse),
            isUse: isUse,
            thicknessUser: thickness,
            sizeUser: size,
            discountsPercent: discountsPercent,
            discountsValue: discountsValue,
            sumSize: sumSize,
            sumAddition: sumAddition,
            nominalPrice: nominalPrice,
            selfCount: selfCount,
            priceSelf: priceSelf,
            priceSumCount: priceSumCount,
            priceEnd: priceEnd
        });

    }, [thickness, size, priceSumCount, discountsPercent, discountsValue, sumSize, sumAddition, nominalPrice, selfCount, priceEnd]);

    // math weight
    useEffect(() => {
        const result = ((size * size) / (product.standardSize * product.standardSize) * (thickness / product.standardThickness) * product.standardWeight) * selfCount;
        setWeight(result);
        onWeight(product, result)
    }, [size, thickness, selfCount]);

    useEffect(() => {

        const additionPrice = +(+nominalPrice + +sumAddition).toFixed(0);

        let price = additionPrice;

        if (discountsValue.length > 0)
            price += discountsValue.reduce((a, b) => a + b.value, 0);

        if (discountsPercent.length > 0)
            price += discountsPercent.reduce((a, b) => (b.percent / 100) + a, 0) * additionPrice;

        setPriceEnd(price);
        setPriceSelf(price);
    }, [discountsPercent, discountsValue, nominalPrice, sumAddition]);

    useEffect(() => {

        const price = priceEnd == priceSelf ? priceEnd * selfCount : priceSelf * selfCount;
        
        setPriceSumCount(price);
        onSum(product, price);
    }, [priceEnd, priceSelf, selfCount]);

    return <div className={styles.productItem}>
        <Table bordered hover striped>
            <thead>
                <tr>
                    <th>
                        Параметры
                    </th>
                    <th>
                        Допики.
                    </th>
                    <th>
                        Скидки
                    </th>
                    <th>
                        Кол-во
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>
                        {
                            currentRangeSizes.map(si => <Badge key={si.id} style={{
                                marginRight: '4px',
                                cursor: 'pointer'
                            }} onClick={() => setSize(si.size)}>{si.size}</Badge>)
                        }
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Размер"
                            className="mb-3"
                        >
                            <Form.Control
                                disabled={!isUse}
                                value={size}
                                onChange={e => setSize(e.target.value)}
                                type="number"
                                placeholder=""
                            />
                        </FloatingLabel>

                        <FloatingLabel
                            controlId={`floatingInput-${product.id}`}
                            label={`Толщина`}
                        >
                            <Form.Select
                                disabled={!isUse}
                                value={thickness}
                                placeholder=""
                                onChange={e => setThickness(e.target.value)}
                            >
                                <option value={product.standardThickness}>{product.standardThickness}</option>
                                {
                                    currentRangeThickness.map(item => <option key={item.id} value={item.thickness}>{item.thickness}</option>)
                                }
                            </Form.Select>
                        </FloatingLabel>
                    </th>
                    <th>
                        {
                            currentAdditions && currentAdditions.length > 0 ?
                                currentAdditions.map(o => <Form.Group className={styles.additionItem} key={o.id}>
                                    <Form.Check
                                        disabled={!isUse}
                                        type="switch"
                                        id={`product-${product.id}-addition-${o.id}`}
                                        style={{ marginLeft: 12 }}
                                        label={<span id={`product-${product.id}-addition-${o.id}`}>{`${o.name} (${o.price}₽)`}</span>}
                                        onChange={e => setSumAddition(e.target.checked ? sumAddition + o.price : sumAddition - o.price) || (o.isUse = e.target.checked)}
                                    />

                                </Form.Group>)
                                : ''
                        }
                    </th>
                    <th>
                        <HiMiniReceiptPercent onClick={() => {
                            const value = prompt('Скидка в процентах (Без знака %)', 10)
                            setDiscountsPercent([...discountsPercent, { id: percentId, percent: +value * -1 }]);
                            setPercentId(percentId + 1);
                        }} style={{ width: 24, height: 24, color: 'green' }} />
                        <FaRubleSign onClick={() => {
                            const value = prompt('Скидка в рублях', 10)
                            setDiscountsValue([...discountsValue, { id: valueId, value: +value * -1 }]);
                            setValueId(valueId + 1);
                        }} style={{ width: 24, height: 24, color: 'green' }} />
                        {
                            discountsPercent.map(p => <div key={p.id} className={styles.productPriceItem}>
                                <span className={styles.price_name} onClick={() => setDiscountsPercent([...discountsPercent.filter(f => f.id != p.id)])}>Скидка в %</span>
                                <span className={styles.price_separator} />
                                <span className={styles.price_value}>{p.percent}%</span>
                            </div>)
                        }

                        {
                            discountsValue.map(p => <div key={p.id} className={styles.productPriceItem}>
                                <span className={styles.price_name} onClick={() => setDiscountsValue([...discountsValue.filter(f => f.id != p.id)])}>Скидка в ₽</span>
                                <span className={styles.price_separator} />
                                <span className={styles.price_value}>{p.value}₽</span>
                            </div>)
                        }
                    </th>
                    <th>
                        {
                            [3, 9, 18, 50, 100, 150].map(si => <Badge key={si} style={{
                                marginRight: '4px',
                                cursor: 'pointer'
                            }} onClick={() => setSelfCount(si)}>{si}</Badge>)
                        }
                        <FloatingLabel
                            controlId={`floatingInput-${product.id}`}
                            label={`Кол-во`}
                            className="mb-3"
                        >
                            <Form.Control
                                disabled={!isUse}
                                value={selfCount}
                                placeholder=""
                                onChange={e => {

                                    const value = e.target.value;

                                    if (!Number(value))
                                        return;

                                    setSelfCount(value);

                                    if (value <= 0)
                                        setErrorToast({ key: 'minZeroCount', message: `К сожалению кол-во продукции не может быть менее 1`, variant: 'danger' })
                                    else if (errorToast?.key == 'minZeroCount')
                                        setErrorToast();

                                    if (value % 1 > 0)
                                        setErrorToast({ key: 'numberPoint', message: `К сожалению кол-во продукции не может быть дробным`, variant: 'danger' })
                                    else if (errorToast?.key == 'numberPoint')
                                        setErrorToast();
                                }}
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId={`floatingInput-${product.id}`}
                            label={`Цена за шт.`}
                        >
                            <Form.Control
                                disabled={!isUse}
                                value={priceSelf}
                                placeholder=""
                                onChange={e => setPriceSelf(e.target.value)}
                            />
                        </FloatingLabel>
                    </th>
                </tr>
                <tr>
                    <th>
                        <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Номинал</span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{nominalPrice}₽</span>
                        </div>
                    </th>
                    <th>
                        <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Допики</span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{sumAddition}₽</span>
                        </div>
                    </th>
                    <th>
                        <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Цена (шт.)</span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{priceEnd}₽</span>
                        </div>
                    </th>
                    <th>
                        <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Сумма</span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{priceSumCount}₽</span>
                        </div>
                    </th>
                </tr>
            </tbody>
        </Table>
        {
            errorToast ?
                <ToastContainer
                    style={{
                        position: 'fixed',
                        zIndex: 1,
                        right: 20,
                        bottom: 20
                    }}
                >
                    <Toast
                        className="d-inline-block m-1"
                        bg={errorToast.variant}
                        onClose={() => setErrorToast()}
                    >
                        <Toast.Header>
                            <strong className="me-auto">Внимание!</strong>
                        </Toast.Header>
                        <Toast.Body className={'text-white'}>
                            {errorToast.message}
                        </Toast.Body>
                    </Toast>
                </ToastContainer> : ''
        }
        <div className={styles.productPriceItem} style={{ width: '20%', justifySelf: 'end' }}>
            <span className={styles.price_name}>Вес</span>
            <span className={styles.price_separator} />
            <span className={styles.price_value}>{+weight.toFixed(2)}гр</span>
        </div>
    </div>
}