import React, { useState } from 'react'
import {
	Button,
	Form,
	ListGroup,
	Modal,
	Stack,
	Tab,
	Tabs,
} from 'react-bootstrap'

const MobileTable = ({ worker,
	jwt,
	updateWorker,
	deleteWorker,
	workerCategories,
	workerTypes,
	mathSalaryTypes,
	employmentTypes,
	mathTimeTypes,
	qualificationTypes,
	handleDelete }) => {

	const [isDeleteModalShow, setIsDeleteModalShow] = useState(false)
	const [validated, setValidated] = useState(false)
	const [workerData, setWorkerData] = useState(worker)

	const [isEdit, setIsEdit] = useState(false);

	const deleteWorkerFunction = () => {
		handleDelete(worker.id)
		setIsDeleteModalShow(!isDeleteModalShow)
		deleteWorker(jwt, workerData.id)
	}

	const handleSubmit = event => {
		const form = event.currentTarget
		event.preventDefault()
		if (form.checkValidity() === false) {
			event.stopPropagation()
		}

		setValidated(true)
		updateWorker(jwt, workerData.id, workerData)
	}

	if(!isEdit)
		return <ListGroup.Item action onClick={() => setIsEdit(true)}>{worker.firstName} {worker.secondName} {worker.thirdName} ({worker.name} - устарело)</ListGroup.Item>

	return (
		<ListGroup.Item
			style={{
				border: '1px solid black',
				marginBottom: '20px',
			}}
		>
			{isDeleteModalShow ? (
				<Modal
					show={isDeleteModalShow}
					onHide={() => setIsDeleteModalShow(!isDeleteModalShow)}
				>
					<Modal.Header>Удалить сотрудника</Modal.Header>
					<Modal.Body>
						Вы действительно хотите <b>Удалить</b> сотрудника?
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant='secondary'
							onClick={() => setIsDeleteModalShow(!isDeleteModalShow)}
						>
							Закрыть
						</Button>
						<Button variant='danger' onClick={() => deleteWorkerFunction()}>
							Удалить
						</Button>
					</Modal.Footer>
				</Modal>
			) : (
				''
			)}
			<Form validated={validated} onSubmit={handleSubmit}>
				<Tabs fill>
					<Tab eventKey='basic_information' title='Основное'>
						<Form.Group>
							<Form.Label>
								<b>Фамилия</b>
							</Form.Label>
							<Form.Control
								required
								className='mb-2'
								type='string'
								value={workerData.secondName}
								onChange={e => {
									setWorkerData({
										...workerData,
										secondName: e.target.value,
									})
								}}
							/>
							<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label>
								<b>Имя</b>
							</Form.Label>
							<Form.Control
								required
								className='mb-2'
								type='string'
								value={workerData.firstName}
								onChange={e => {
									setWorkerData({
										...workerData,
										firstName: e.target.value,
									})
								}}
							/>
							<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label>
								<b>Отчество</b>
							</Form.Label>
							<Form.Control
								className='mb-2'
								type='string'
								value={workerData.thirdName}
								onChange={e => {
									setWorkerData({
										...workerData,
										thirdName: e.target.value,
									})
								}}
							/>
							<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
						</Form.Group>
						<Form.Group>
							<Form.Label>
								<b>ФИО (Устарело)</b>
							</Form.Label>
							<Form.Control
								readOnly
								disabled
								className='mb-2'
								type='string'
								value={workerData.name}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>
								<b>Номер телефона</b>
							</Form.Label>
							<Form.Control
								required
								type='tel'
								value={workerData.phone}
								onChange={e => {
									setWorkerData({
										...workerData,
										phone: e.target.value,
									})
								}}
							/>
							<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
						</Form.Group>
						<b className='mt-2 mb-2' style={{ display: 'inline-block' }}>
							Категория
						</b>
						<Form.Select
							value={workerData.categoryId}
							a
							onChange={e => {
								setWorkerData({
									...workerData,
									categoryId: e.target.value,
								})
							}}
						>
							{workerCategories && workerCategories.length > 0
								? workerCategories.map(workerCategory => (
									<option key={workerCategory.id} value={workerCategory.id}>
										{workerCategory.name}
									</option>
								))
								: ''}
						</Form.Select>
					</Tab>
					<Tab eventKey='additional_information' title='Дополнительное'>
						<b className='mt-2 mb-2' style={{ display: 'inline-block' }}>
							Тип работника
						</b>
						<Form.Select
							value={workerData.typeId}
							onChange={e => {
								setWorkerData({ ...workerData, typeId: e.target.value })
							}}
						>
							{workerTypes && workerTypes.length > 0
								? workerTypes.map(workerType => (
									<option key={workerType.id} value={workerType.id}>
										{workerType.name}
									</option>
								))
								: ''}
						</Form.Select>
						<b className='mt-2 mb-2' style={{ display: 'inline-block' }}>
							Тип расчета
						</b>
						<Form.Select
							value={workerData.mathSalaryId}
							onChange={e => {
								setWorkerData({
									...workerData,
									mathSalaryId: e.target.value,
								})
							}}
						>
							{mathSalaryTypes && mathSalaryTypes.length > 0
								? mathSalaryTypes.map(mathSalaryType => (
									<option key={mathSalaryType.id} value={mathSalaryType.id}>
										{mathSalaryType.name}
									</option>
								))
								: ''}
						</Form.Select>
						<b className='mt-2 mb-2' style={{ display: 'inline-block' }}>
							Тип трудоустройства
						</b>
						<Form.Select
							style={{ display: 'inline-block' }}
							value={workerData.employmentTypeId}
							onChange={e => {
								setWorkerData({
									...workerData,
									employmentTypeId: e.target.value,
								})
							}}
						>
							{employmentTypes && employmentTypes.length > 0
								? employmentTypes.map(employmentType => (
									<option key={employmentType.id} value={employmentType.id}>
										{employmentType.name}
									</option>
								))
								: ''}
						</Form.Select>
						<b className='mt-2 mb-2' style={{ display: 'inline-block' }}>
							График работы
						</b>
						<Form.Select
							value={workerData.mathTime}
							onChange={e => {
								setWorkerData({
									...workerData,
									mathTime: e.target.value,
								})
							}}
						>
							{mathTimeTypes && mathTimeTypes.length > 0
								? mathTimeTypes.map(mathTimeType => (
									<option key={mathTimeType.id} value={mathTimeType.id}>
										{mathTimeType.name}
									</option>
								))
								: ''}
						</Form.Select>
						<b className='mt-2 mb-2' style={{ display: 'inline-block' }}>
							Квалификация
						</b>
						<Form.Select
							value={workerData.qualificationId}
							onChange={e => {
								setWorkerData({
									...workerData,
									qualificationId: e.target.value,
								})
							}}
						>
							{qualificationTypes && qualificationTypes.length > 0
								? qualificationTypes.map(qualificationTypes => (
									<option
										key={qualificationTypes.id}
										value={qualificationTypes.id}
									>
										{qualificationTypes.name}
									</option>
								))
								: ''}
						</Form.Select>
						<b className='mt-2 mb-2' style={{ display: 'inline-block' }}>
							Часовой пояс
						</b>
						<Form.Control
							type='time'
							value={workerData.zone}
							onChange={e => setWorkerData({ ...workerData, zone: e.target.value })}
						/>

						<b className='mt-2 mb-2' style={{ display: 'inline-block' }}>
							Комментарий
						</b>
						<Form.Control
							as="textarea"
							value={workerData.adminNote}
							onChange={e => setWorkerData({ ...workerData, adminNote: e.target.value })}
						/>
					</Tab>
				</Tabs>
				<Stack className='mt-3' direction='horizontal' gap={3}>
					<Button variant='primary' type='submit'>
						Сохранить
					</Button>
					<Button
						variant='danger'
						onClick={() => setIsDeleteModalShow(!isDeleteModalShow)}
					>
						Удалить
					</Button>
					<Button
						variant='secondary'
						onClick={() => setIsEdit(false)}
					>
						Закрыть
					</Button>
				</Stack>
			</Form>
		</ListGroup.Item>
	)
}

export default MobileTable
