import React, { useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styles from '../Style/home.module.css';
import { Alert, ListGroup, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import { getMeAnalyze } from '../../../apiMethod/Employee/metaShifts';
import { HttpNoData } from '../../../Core';
import { getNameMonth } from './utils';

function Home({ jwt }) {

  const [notifications, setNotifications] = useState([]);
  const [settingMainPage, setSettingMainPage] = useState({});
  const [salary, setSalary] = useState({});
  const [data, setData] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [analytic, setAnalytic] = useState({});
  const [salaryBid, setSalaryBid] = useState(0);

  const [fines, setFines] = useState([]);
  const [monthView, setMonthView] = useState('current'); // current or prev

  const getVariant = (number) => {

    if (number == 0)
      return 'info';

    if (number == 1)
      return 'warning';

    if (number == 2)
      return 'danger';

    return 'warning';
  }

  useEffect(() => {

    window.addEventListener('resize', async function (event) {
      setIsMobile(window.innerWidth <= 640);
    }, true);

    async function didMount() {

      const responseNotifications = await HttpNoData(`/api/v1/notification/${'mainPage'}`, 'GET', jwt);
      const responseSettings = await HttpNoData(`/api/v1/workers/settingMainPage`, 'GET', jwt);
      const responseSalaryBid = await HttpNoData(`/api/v1/salaryBids`, 'GET', jwt);

      if (responseSettings.statusSuccessful)
        setSettingMainPage(responseSettings.data);

      if (responseNotifications.statusSuccessful)
        setNotifications(responseNotifications.data);

      if (responseSalaryBid.statusSuccessful)
        setSalaryBid(responseSalaryBid.data);
    }

    didMount();
  }, []);

  useEffect(() => {

    async function didMount() {

      if (!monthView || monthView == '')
        return;

      const prevDate = new Date(new Date().getTime());
      prevDate.setDate(0);

      const currentDate = (monthView == 'prev' ? prevDate : new Date()).toJSON().split('T')[0];

      const responseSalary = await HttpNoData(`/api/v1/salary?date=${currentDate}`, 'GET', jwt);
      const responseFines = await HttpNoData(`/api/v1/fines?date=${currentDate}`, 'GET', jwt);

      if (responseFines.statusSuccessful)
        setFines(responseFines.data);

      if (responseSalary.statusSuccessful)
        setSalary(responseSalary.data);

      const response = await getMeAnalyze(currentDate, jwt);

      if (!response)
        return;

      analytic.sumPoints = 0;
      analytic.middlePoints = 0;
      analytic.simplePoints = 0;
      analytic.highPoints = 0;
      analytic.upPoints = 0;
      analytic.dayHours = 0;
      analytic.nightHours = 0;
      analytic.dayOffHours = 0;
      analytic.extraWorkPoints = 0;
      analytic.daysOff = 0;
      analytic.isMachines = 0;
      analytic.machinePoints = 0;

      const machineAverage = [];
      for (let i = 0; i < response.length; i++) {
        const element = response[i];

        const dayHours = new Date(`2024-12-12T${element.day}`);
        element.dayHours = dayHours.getHours() + dayHours.getMinutes() / 60;

        const nightHours = new Date(`2024-12-12T${element.night}`);
        element.nightHours = nightHours.getHours() + nightHours.getMinutes() / 60;

        const dayOffHours = new Date(`2024-12-12T${element.dayOff}`);
        element.dayOffHours = dayOffHours.getHours() + dayOffHours.getMinutes() / 60;

        analytic.sumPoints += element.sumPoints;
        analytic.middlePoints += element.middlePoints;
        analytic.simplePoints += element.simplePoints;
        analytic.highPoints += element.highPoints;
        analytic.upPoints += element.upPoints;
        analytic.dayHours += element.dayHours;
        analytic.nightHours += element.nightHours;
        analytic.dayOffHours += element.dayOffHours;
        analytic.extraWorkPoints += element.extraWorkPoints;
        analytic.daysOff += element.isDayOff;

        if (element.isMachines) {
          analytic.isMachines += element.isMachines;
          analytic.machinePoints += element.machinePoints;
          machineAverage.push(element.machinePercent);
        }
      }

      analytic.machinePercent = machineAverage.reduce((a, b) => a + b, 0) / machineAverage.length;
      analytic.sumHours = analytic.dayHours + analytic.nightHours + analytic.dayOffHours;
      setAnalytic({ ...analytic })
      setData(response);
    }

    didMount();

  }, [monthView]);

  if (data == null)
    return <div className={styles.body_load}>
      <Spinner animation="grow" role="status" />
    </div>

  const COLORS = ['#FFBB28', '#0088FE', '#00C49F', '#FF8042'];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.38;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text fontSize={12} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }

  const RechartCircle = ({ title, valueTitle, data }) => {

    return <div className={styles.itemRechart}>
      <div className={styles.titleItem}>{title} - {valueTitle}</div>
      <ResponsiveContainer className={styles.rechart}>
        <PieChart width={'100%'} height={'100%'}>
          <Pie
            data={data}
            cx={isMobile ? '30%' : '50%'}
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>

          {
            isMobile ?
              <Legend formatter={(v, e, i) => `${v} - ${data[i].value}`} layout="vetical" verticalAlign="middle" align="right" /> :
              <Legend formatter={(v, e, i) => `${v} - ${data[i].value}`} />
          }
        </PieChart>
      </ResponsiveContainer>
    </div>
  }

  const bodyPoints = {
    valueTitle: data.map(o => o.sumPoints),
    simplePoints: data.map(o => o.simplePoints),
    middlePoints: data.map(o => o.middlePoints),
    highPoints: data.map(o => o.highPoints),
    upPoints: data.map(o => o.upPoints),
  };

  const bodyHours = {
    valueTitle: data.map(o => o.dayHours + o.nightHours + o.dayOffHours),
    day: data.map(o => o.dayHours),
    night: data.map(o => o.nightHours),
    dayOff: data.map(o => o.dayOffHours),
  };

  const content = <>
    <div className={styles.title}>Общая динамика</div>
    <div className={styles.elements}>
      <div className={styles.rechartContent}>
        <RechartCircle title={'Баллы'} valueTitle={+(bodyPoints.valueTitle.length > 0 ? bodyPoints.valueTitle : [0]).reduce((a, b) => a + b).toFixed(2)} data={[
          {
            name: "Простые",
            value: +(bodyPoints.simplePoints.length > 0 ? bodyPoints.simplePoints : [0]).reduce((a, b) => a + b).toFixed(2)
          },
          {
            name: "Средние",
            value: +(bodyPoints.middlePoints.length > 0 ? bodyPoints.middlePoints : [0]).reduce((a, b) => a + b).toFixed(2)
          },
          {
            name: "Сложные",
            value: +(bodyPoints.highPoints.length > 0 ? bodyPoints.highPoints : [0]).reduce((a, b) => a + b).toFixed(2)
          },
          {
            name: "С надбавкой",
            value: +(bodyPoints.upPoints.length > 0 ? bodyPoints.upPoints : [0]).reduce((a, b) => a + b).toFixed(2)
          }
        ]} />

        <RechartCircle title={'Часы'} valueTitle={+(bodyHours.valueTitle.length > 0 ? bodyHours.valueTitle : [0]).reduce((a, b) => a + b).toFixed(2)} data={[
          {
            name: "Дневные",
            value: +(bodyHours.day.length > 0 ? bodyHours.day : [0]).reduce((a, b) => a + b).toFixed(2)
          },
          {
            name: "Ночные",
            value: +(bodyHours.night.length > 0 ? bodyHours.night : [0]).reduce((a, b) => a + b).toFixed(2)
          },
          {
            name: "Выходные",
            value: +(bodyHours.dayOff.length > 0 ? bodyHours.dayOff : [0]).reduce((a, b) => a + b).toFixed(2)
          }
        ]} />

      </div>
      <div className={styles.textContent}>
        <label className={styles.labelTitle}>Среднее кол-во баллов за смену - {(data.length > 0 ? (analytic.sumPoints / data.length) : 0).toFixed(2)}</label>
        <label className={styles.labelTitle}>Средняя длительность смены - {(data.length > 0 ? (analytic.sumHours / data.length) : 0).toFixed(2)}</label>
        {
          analytic.isMachines ?
            <div className={styles.item}>
              <div className={styles.footerText}>
                <div className={styles.labelTitle} style={{ fontSize: 24 }}>Лазера</div>
                <div className={styles.labelTitle}>Сред. нагрузка - {analytic.machinePercent}%</div>
                <div className={styles.labelTitle}>Баллы - {analytic.machinePoints}</div>
              </div>
            </div>
            : null
        }
      </div>
    </div>
    {
      notifications && notifications.length > 0 ?
        notifications.map(n => <Alert key={n.id} variant={getVariant(n.priority)}>{n.message}</Alert>) : ''
    }
    {
      settingMainPage && (settingMainPage.isPremium || settingMainPage.isFines || settingMainPage.isSalary || settingMainPage.isKpd) ?
        <Table striped bordered hover style={{ maxWidth: 1440 }}>
          <thead>
            <tr>
              {settingMainPage.isPremium ? <th>Премия</th> : ''}
              {settingMainPage.isFines ? <th>Штрафы</th> : ''}
              {settingMainPage.isSalary ? <th>Зарплата (на руки)</th> : ''}
              {settingMainPage.isSalaryBid ? <th>Норма</th> : ''}
              {settingMainPage.isKpd ? <th>КПД</th> : ''}
            </tr>
          </thead>
          <tbody>
            <tr>
              {settingMainPage.isPremium ? <th>{Number.parseFloat(salary.premium).toFixed(2)}</th> : ''}
              {settingMainPage.isFines ? <th>{Number.parseFloat(salary.fines).toFixed(2)}</th> : ''}
              {settingMainPage.isSalary ? <th>{Number.parseFloat(salary.salaryTax).toFixed(2)}</th> : ''}
              {settingMainPage.isSalaryBid ? <th>{Number.parseFloat(salaryBid).toFixed(2)}</th> : ''}
              {settingMainPage.isKpd ? <th>{Number.parseFloat(salary.kpd).toFixed(2)}%</th> : ''}
            </tr>
          </tbody>
        </Table>
        : ''
    }
    {
      fines && fines.length > 0 ?
        <>
          <h5>Штрафы</h5>
          <ListGroup>
            {fines.map(f => <ListGroup.Item key={f.id}>{f.message}</ListGroup.Item>)}
          </ListGroup>
        </> : ''
    }
  </>

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <Tabs
          activeKey={monthView}
          onSelect={(k) => setMonthView(k)}
          justify
        >
          <Tab eventKey={'prev'} title={getNameMonth(new Date().getMonth() - 1 < 0 ? 11 : new Date().getMonth() - 1)}>
            {content}
          </Tab>
          <Tab eventKey={'current'} title={getNameMonth(new Date().getMonth())}>
            {content}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
export default Home;
