import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { HttpData } from "../../../../Core";

export default function TypeModalCreate({ jwt, show, onHide, onCreated }){

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const onSubmit = async(e) => {
        e.preventDefault();

        const response = await HttpData('/api/v1/calculateOrderCost/TypeProductCategories', 'POST', jwt, {
            name: name,
            description: description
        });

        if(response.statusSuccessful)
            onCreated(response.data);
        else alert(`Не получилось создать: ${response.error}`)
    }

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>Создание типа</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Название</Form.Label>
                <Form.Control
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Описание</Form.Label>
                <Form.Control 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="success" type="submit">Создать</Button>
        </Modal.Footer>
        </Form>
    </Modal>
}