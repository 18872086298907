import { useState, useEffect } from "react";
import { HttpNoData } from "../../../../Core";
import { Alert, Button, Col, Form, InputGroup, ListGroup, Row, Stack } from "react-bootstrap";
import { MdEdit, MdPlusOne } from "react-icons/md";
import { HiPlus } from "react-icons/hi";
import styles from './style.module.css';
import TypeModalCreate from "./TypeModalCreate";
import TypeModalEdit from "./TypeModalEdit";
import CategoryModalCreate from "./CategoryModalCreate";
import CategoryModalEdit from "./CategoryModalEdit";
import ProductModalCreate from "./ProductModalCreate";
import ProductModalEdit from "./ProductModalEdit";
import AdditionInOrderModalCreate from "./AdditionInOrderModalCreate";
import AdditionOrderModalEdit from "./AdditionInOrderModalEdit";

export default function Main({ jwt }) {

    const [typeCategories, setTypeCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [priceOnWeight, setPriceOnWeight] = useState(1000);
    const [additionsInOrder, setAdditionsInOrder] = useState([]);

    const [priceOnWeightUser, setPriceOnWeightUser] = useState(0);

    const [createClass, setCreateClass] = useState();
    const [editClass, setEditClass] = useState('');

    useEffect(() => {

        async function didMount() {
            const additionsResponse = await HttpNoData('/api/v1/calculateOrderCost/orders/additions', 'GET', jwt);
            const typesResponse = await HttpNoData('/api/v1/calculateOrderCost/typeProductCategories', 'GET', jwt);
            const categoriesResponse = await HttpNoData('/api/v1/calculateOrderCost/productCategories', 'GET', jwt);
            const productsResponse = await HttpNoData('/api/v1/calculateOrderCost/products', 'GET', jwt);
            const priceOnWeightResponse = await HttpNoData('/api/v1/calculateOrderCost/priceOnWeight', 'GET', jwt);

            setAdditionsInOrder(additionsResponse.data ?? []);
            setTypeCategories(typesResponse.data ?? []);
            setCategories(categoriesResponse.data ?? [])
            setProducts(productsResponse.data ?? []);
            setPriceOnWeight(priceOnWeightResponse.data ?? 1000)
            setPriceOnWeightUser(priceOnWeightResponse.data ?? 1000);
        }

        didMount();

    }, []);

    const updatePrice = async() => {
        const priceOnWeightResponse = await HttpNoData(`/api/v1/calculateOrderCost/priceOnWeight/${priceOnWeightUser}`, 'PUT', jwt);

        if(priceOnWeightResponse.statusSuccessful)
            setPriceOnWeight(priceOnWeightUser);
        else alert(`Не получилось обновить: ${priceOnWeightResponse.error}`)
    };

    const currentId = editClass.includes(':') ? +editClass.split(':')[1] : 0;

    return <Stack className={styles.bodyMain} gap={3}>
        <Row>
            <Col md={3}>
                <InputGroup>
                    <InputGroup.Text>Цена за 3кг</InputGroup.Text>
                    <Form.Control
                        type="number"
                        value={priceOnWeightUser}
                        onChange={(e) => setPriceOnWeightUser(e.target.value)}
                    />
                    <Button variant="success" onClick={updatePrice}>Сохранить</Button>
                </InputGroup>
            </Col>
        </Row>
        <Row>
            <Col md>
                <h4>Типы</h4>
                <ListGroup>
                    {
                        typeCategories.length > 0 ?
                            typeCategories.map(o => <ListGroup.Item key={o.id}>
                                <div className={styles.listItem}>
                                    <span>{o.name}</span>
                                    <Button variant="warning" onClick={() => setEditClass(`type:${o.id}`)}>
                                        <MdEdit />
                                    </Button>
                                </div>
                            </ListGroup.Item>)
                            : <Alert variant="warning">Пусто</Alert>
                    }
                    <Button variant="success"  onClick={() => setCreateClass('type')}>
                        <HiPlus />
                    </Button>
                </ListGroup>
            </Col>
            <Col md>
                <h4>Категории</h4>
                <ListGroup>
                    {
                        categories.length > 0 ?
                            categories.map(o => <ListGroup.Item key={o.id}>
                                <div className={styles.listItem}>
                                    <span>{o.name}</span>
                                    <Button variant="warning" onClick={() => setEditClass(`category:${o.id}`)}>
                                        <MdEdit />
                                    </Button>
                                </div>
                            </ListGroup.Item>)
                            : <Alert variant="warning">Пусто</Alert>
                    }
                    <Button variant="success"  onClick={() => setCreateClass('category')}>
                        <HiPlus />
                    </Button>
                </ListGroup>
            </Col>
            <Col md>
                <h4>Продукция</h4>
                <ListGroup>
                    {
                        products.length > 0 ?
                            products.map(o => <ListGroup.Item key={o.id}>
                                <div className={styles.listItem}>
                                    <span>{o.name}</span>
                                    <Button variant="warning" onClick={() => setEditClass(`product:${o.id}`)}>
                                        <MdEdit />
                                    </Button>
                                </div>
                            </ListGroup.Item>)
                            : <Alert variant="warning">Пусто</Alert>
                    }
                    <Button variant="success"  onClick={() => setCreateClass('product')}>
                        <HiPlus />
                    </Button>
                </ListGroup>
            </Col>
            <Col md>
                <h4>Дополнения к заказу</h4>
                <ListGroup>
                    {
                        additionsInOrder.length > 0 ?
                            additionsInOrder.map(o => <ListGroup.Item key={o.id}>
                                <div className={styles.listItem}>
                                    <span>{o.name}</span>
                                    <Button variant="warning" onClick={() => setEditClass(`addition:${o.id}`)}>
                                        <MdEdit />
                                    </Button>
                                </div>
                            </ListGroup.Item>)
                            : <Alert variant="warning">Пусто</Alert>
                    }
                    <Button variant="success"  onClick={() => setCreateClass('addition')}>
                        <HiPlus />
                    </Button>
                </ListGroup>
            </Col>
        </Row>
        {
            createClass == 'type' ? 
            <TypeModalCreate show={createClass == 'type'}
                onCreated={c => setTypeCategories([...typeCategories, c])}
                onHide={() => setCreateClass()}
                jwt={jwt}
                /> : ''
        }
        {
            createClass == 'category' ? 
            <CategoryModalCreate show={createClass == 'category'}
                onCreated={c => setCategories([...categories, c])}
                onHide={() => setCreateClass()}
                types={typeCategories}
                jwt={jwt}
                /> : ''
        }
        {
            createClass == 'product' ? 
            <ProductModalCreate show={createClass == 'product'}
                onCreated={c => setProducts([...products, c])}
                onHide={() => setCreateClass()}
                categories={categories}
                jwt={jwt}
                /> : ''
        }
        {
            createClass == 'addition' ? 
            <AdditionInOrderModalCreate show={createClass == 'addition'}
                onCreated={c => setAdditionsInOrder([...additionsInOrder, c])}
                onHide={() => setCreateClass()}
                jwt={jwt}
                /> : ''
        }



        {
            editClass.includes('type') ?
            <TypeModalEdit 
                show={true}
                jwt={jwt}
                onDeleted={() => setTypeCategories([...typeCategories.filter(f => f.id != currentId)]) || setEditClass('')}
                onHide={() => setEditClass('')}
                onUpdated={(u) => {
                    const index = typeCategories.findIndex(f => f.id == currentId)
                    typeCategories[index] = u;
                    setTypeCategories([...typeCategories])
                }}
                type={typeCategories.find(f => f.id == currentId)}
                /> :''
        }
        {
            editClass.includes('category') ?
            <CategoryModalEdit 
                show={true}
                jwt={jwt}
                types={typeCategories}
                onDeleted={() => setCategories([...categories.filter(f => f.id != currentId)]) || setEditClass('')}
                onHide={() => setEditClass('')}
                onUpdated={(u) => {
                    const index = categories.findIndex(f => f.id == currentId)
                    categories[index] = u;
                    setCategories([...categories])
                }}
                category={categories.find(f => f.id == currentId)}
                /> :''
        }
        {
            editClass.includes('product') ?
            <ProductModalEdit 
                show={true}
                jwt={jwt}
                categories={categories}
                onDeleted={() => setProducts([...products.filter(f => f.id != currentId)]) || setEditClass('')}
                onHide={() => setEditClass('')}
                onUpdated={(u) => {
                    const index = products.findIndex(f => f.id == currentId)
                    products[index] = u;
                    setProducts([...products])
                }}
                product={products.find(f => f.id == currentId)}
                /> :''
        }
        {
            editClass.includes('addition') ?
            <AdditionOrderModalEdit 
                show={true}
                jwt={jwt}
                onDeleted={() => setAdditionsInOrder([...additionsInOrder.filter(f => f.id != currentId)]) || setEditClass('')}
                onHide={() => setEditClass('')}
                onUpdated={(u) => {
                    const index = additionsInOrder.findIndex(f => f.id == currentId)
                    additionsInOrder[index] = u;
                    setAdditionsInOrder([...additionsInOrder])
                }}
                addition={additionsInOrder.find(f => f.id == currentId)}
                /> :''
        }
        
    </Stack>
}