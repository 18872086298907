import Product from "./Product";
import { useEffect, useState } from "react";
import styles from './style.module.css';
import { Form } from "react-bootstrap";

export default function ProductCategory({ jwt, category, products, onUpdate, onSum, onWeight }) {

    const [currentProduct, setCurrentProduct] = useState();
    const [isUse, setIsUse] = useState(true);

    const [sum, setSum] = useState(0);
    const [weight, setWeight] = useState(0);

    useEffect(() => {
        
        if(!isUse){
            onSum(category, 0);
            onWeight(category, 0);
        } else {
            onSum(category, sum);
            onWeight(category, weight);
        }
    }, [isUse]);

    useEffect(() => {
        onSum(category, sum);
    }, [sum]);

    useEffect(() => {
        onWeight(category, weight);
    }, [weight]);

			const currentProducts = products.filter(f => f.categoryId == category.id);

    return <div className={styles.productCategory}>
           <div className={styles.container_category}>
            <h3 style={{ marginRight: '8px'}}>{category.name} - </h3>
            {
                !currentProduct ?
                <Form.Select
                defaultValue={-1}
                style={{width: '166px'}}
                onChange={e => setCurrentProduct(currentProducts.find(f => f.id == e.target.value))}
                >
                        <option value={-1}>Не выбрано</option>
                        {
                            currentProducts.map(pr => <option key={pr.id} value={pr.id}>{pr.name}</option>)
                        }
                    </Form.Select>
                    : <>
                    <h3>{currentProduct.name}</h3>
                    <Form.Check
                    id={`checkbox-product-${currentProduct.id}`}
                    checked={isUse}
                    onChange={e => setIsUse(e.target.checked)}
                />
                </>
                }
                    </div>
        {
            currentProduct ?
                <Product
                    jwt={jwt}
                    isUse={isUse}
                    onUpdate={onUpdate}
                    product={currentProduct}
                    onSum={(p, s) => setSum(s)}
                    onWeight={(p, s) => setWeight(s)} />
                : ''
        }
    </div>
}