import { useState } from "react";
import { Alert, Stack } from "react-bootstrap";
import ProductCategory from "./ProductCategory";
import styles from './style.module.css';
import { getRandomColor } from "../../../Core";
import { updatePropertyInList } from "./utils";

export default function TypeProductCategory({ jwt, type, products, onUpdate, categories, onSum, onWeight  }) {

  const [currentCategory] = useState(categories.filter(f => f.categoryId == type.id));
  const [amounts, setAmounts] = useState([]);

  return <div className={styles.typeProductCategory}>
    <Stack gap={3} direction="horizontal">
      <div className={styles.typeColor} style={{ backgroundColor: getRandomColor()}}/>
      <h1>{type.name}</h1>
    </Stack>
    <div className={styles.bodyCategories}>
      {
        currentCategory && currentCategory.length > 0 ?
          currentCategory.flatMap(o => { if(o.countView > 1) { const fl = []; fl.length = o.countView; fl.fill(o, 0, o.countView); return fl; } return o; })
            .map((o, index) => <ProductCategory 
            onSum={(c, s) => setAmounts(updatePropertyInList(amounts, 'key', `${c.id}-${index}`, 'sum', s, (s) => onSum(type, s)))} 
            onWeight={(c, s) => setAmounts(updatePropertyInList(amounts,'key', `${c.id}-${index}`, 'weight', s, (s) => onWeight(type, s)))} 
            key={index}
            onUpdate={(p) => onUpdate({
              ...p,
              key: `${o.id}-${index}`
            })}
            category={o} 
            products={products} 
            jwt={jwt} />) :
          <Alert variant="warning">Пусто</Alert>
      }
    </div>
  </div>
}
