import React, { useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { IoArrowBackCircle } from "react-icons/io5";
import { MdCancel, MdCheckCircle, MdEdit } from "react-icons/md";
import { TimeSpanParse } from "../../Core";
import { extraWorkPullCreate, extraWorkPullDelete, extraWorkPullUpdateDescription, extraWorkPullUpdateValue, extraWorksNoUse, getShiftsAll, laserOnShiftCreate, laserOnShiftDelete, lasers, shiftUpdateDate, shiftUpdateDayOff, shiftUpdateMachinePercent } from "../../apiMethod/shifts";
import styles from './ShiftEdit.module.css';
import { Alert, Form } from "react-bootstrap";

const dateFormatToView = (data) => {
    const temp = new Date(data);
    return (new Date(temp.getTime() - temp.getTimezoneOffset() * 60000).toISOString()).slice(0, -1)
}

const dateFormatToValue = (view) => {
    const fakeUtcTime = new Date(`${view}Z`);
    return new Date(fakeUtcTime.getTime() + fakeUtcTime.getTimezoneOffset() * 60000);
}

const ExtraWorkPullModeEdit = ({ extraWorkPull, stateAction, onCompleted, onCancel }) => {

    const [value, setValue] = useState(extraWorkPull.value);
    const [description, setDescription] = useState(extraWorkPull.description);

    return <div className={styles.extraWorkPullModeEdit}>
        <div>{extraWorkPull.name}</div>
        <div className={styles.extraWorkPullProperties}>
            <div className={styles.extraWorkPullProperty}>
                <div>Значение</div>
                <input style={{ width: '98%' }} value={value} onChange={(e) => setValue(e.target.value)} />
            </div>
            <div className={styles.extraWorkPullProperty}>
                <div>Описание</div>
                <input style={{ width: '98%' }} value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
        </div>
        <button className={styles.buttonSaveActionExtraWorkPull} onClick={() => onCompleted(value, description)}>{stateAction}</button>
        <button className={styles.buttonCancelActionExtraWorkPull} onClick={onCancel}>закрыть</button>
    </div>
}

function ShiftEdit() {

    const [jwt] = useState(localStorage.getItem('jwtToken'));
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString().split('.').reverse().join('-'));
    const [shifts, setShifts] = useState([]);

    const [machines, setMachines] = useState([]);
    useEffect(() => {
        async function DidMount() {
            setMachines(await lasers(jwt));
        }

        DidMount();
    }, []);

    const ShiftEditMode = ({ shift, onCancel }) => {

        const [extraWorkPulls, setExtraWorksPulls] = useState(shift.extraWorksPull);
        const [start, setStart] = useState(shift.start);
        const [end, setEnd] = useState(shift.end);
        const [machinePercent, setMachinePercent] = useState(shift.machinePercent);
        const [currentExtraWorkPull, setCurrentExtraWorkPull] = useState(null);
        const [isError, setIsError] = useState(false);

        const DeleteExtraWorkPull = async (extraWorkPull) => {

            const state = await extraWorkPullDelete(shift.id, extraWorkPull.id, jwt);

            const copy = [...extraWorkPulls.filter(o => o.id != extraWorkPull.id)];
            setExtraWorksPulls(copy);
        }

        const CreateExtraWorkPull = async (extraWorkId, value, description) => {

            const create = await extraWorkPullCreate(shift.id, extraWorkId, value, description, jwt);

            const copy = [...extraWorkPulls];
            copy.push(create);
            setExtraWorksPulls(copy);
        }

        const UpdateDate = async (start, end) => {
            const state = await shiftUpdateDate(shift.id, start, end, jwt);
            const responseMachinePercent = await shiftUpdateMachinePercent(shift.id, machinePercent, jwt);
        }

        const ExtraWorkPulls = () => {
            const [isCreate, setIsCreate] = useState(false);

            const CreateExtraWorkPullView = (data) => {
                const [extraWorksWorld, setExtraWorksWorld] = useState([]);
                const [valueCreateWork, setValueCreateWork] = useState(0);
                const [currentExtraWorkId, setCurrentExtraWorkId] = useState(1);

                useEffect(() => {
                    async function DidMount() {
                        const data = await extraWorksNoUse(shift.id, jwt);
                        setExtraWorksWorld(data ? data : []);

                        if (data && data.length > 0)
                            setCurrentExtraWorkId(data[0].id);
                    }

                    DidMount();
                }, []);

                const selectWorks = extraWorksWorld.map(item => <option key={item.id} value={item.id}>{item.name}</option>);

                return <div className={isCreate ? styles.panelCreate : styles.objectOFF}>
                    <div className={styles.panelCreate_content}>
                        <select className={styles.selectWorks} id='jobSelect' value={currentExtraWorkId} onChange={(e) => setCurrentExtraWorkId(e.target.value)}>
                            {selectWorks}
                        </select>
                        <input type='number' className={styles.inputItemEdit} value={valueCreateWork} onChange={(e) => { setValueCreateWork(e.target.value); }} />
                        <div className={styles.buttonsEdit}>
                            <button className={styles.buttonEditBack} onClick={() => { setIsCreate(false); }}><IoArrowBackCircle className={styles.iconEdit} /></button>
                            <button className={styles.buttonEditSave} onClick={() => { setIsCreate(false); CreateExtraWorkPull(currentExtraWorkId, valueCreateWork, '') }}><MdCheckCircle className={styles.iconEdit} /></button>
                        </div>
                    </div>
                </div>
            }

            return <div className={styles.extra_works}>
                {
                    extraWorkPulls.length > 0 ?
                        extraWorkPulls.map(work => <button
                            key={work.id} className={styles.extra_work}
                            onClick={() => setCurrentExtraWorkPull(work)}>
                            <span>
                                {work.name}: {work.value}
                            </span>
                            <MdCancel className={styles.extraWorkDelete} onClick={(e) => {
                                e.stopPropagation();
                                DeleteExtraWorkPull(work);
                            }} />
                        </button>)
                        : 'Работы отсутствуют'
                }
                <CiCirclePlus className={styles.plusExtraWork} onClick={() => setIsCreate(true)} />
                {
                    isCreate ? <CreateExtraWorkPullView /> : null
                }
            </div>
        }

        const Machines = () => {
            const [selectMachines, setSelectMachines] = useState(shift.machines);

            return <div className={styles.extra_works}>
                {
                    machines.length > 0 ?
                        machines.map(machine => <button
                            key={machine.id} className={styles.extra_work}>
                            <span>
                                {machine.name}
                            </span>
                            {
                                selectMachines.findIndex(o => o.id == machine.id) == -1 ?
                                    <CiCirclePlus style={{ margin: 5 }} className={styles.plusExtraWork} onClick={async (e) => {
                                        e.stopPropagation();
                                        const copy = [...selectMachines];
                                        copy.push(machine);
                                        setSelectMachines(copy);
                                        await laserOnShiftCreate(shift.id, machine.id, jwt);
                                    }} />
                                    : <MdCancel className={styles.extraWorkDelete} onClick={async (e) => {
                                        e.stopPropagation();
                                        const copy = [...selectMachines.filter(o => o.id != machine.id)];
                                        setSelectMachines(copy);
                                        await laserOnShiftDelete(shift.id, machine.id, jwt);
                                    }} />
                            }
                        </button>)
                        : 'Лазеры отсутствуют'
                }
            </div>
        };



        return <div className={styles.shiftModeEdit}>
            <div>{shift.worker}</div>
            <div className={styles.datePanel}>
                Начало:
                <input className={styles.dateInputShift} onChange={(e) => { setStart(dateFormatToValue(e.target.value).toJSON()) }} value={dateFormatToView(start)} type="datetime-local" />
            </div>
            <div className={styles.datePanel}>
                Конец:
                <input className={styles.dateInputShift} onChange={(e) => { setEnd(dateFormatToValue(e.target.value).toJSON()) }} value={dateFormatToView(end)} type="datetime-local" />
            </div>
            <Form>
                <Form.Label>Нагрузка лазеров %</Form.Label>
                <Form.Control
                        type="number"
                        placeholder="1 - 100%, 0.5 - 50%"
                        required
                        value={machinePercent}
                        onChange={(e) => setMachinePercent(e.target.value)}
                    />
            </Form>
            <button className={styles.buttonSaveDate}
                disabled={CheckDateOnCopy(end, shift.end) && CheckDateOnCopy(start, shift.start) && shift.machinePercent == machinePercent}
                onClick={() => UpdateDate(start, end)}>сохранить</button>
            <ExtraWorkPulls />
            <Machines />
            {
                currentExtraWorkPull ?
                    <React.Fragment>
                        <ExtraWorkPullModeEdit extraWorkPull={currentExtraWorkPull}
                            onCancel={() => setCurrentExtraWorkPull(null)}
                            onCompleted={async (value, description) => {

                                const upValue = await extraWorkPullUpdateValue(shift.id, currentExtraWorkPull.id, value, jwt);
                                const upDescription = await extraWorkPullUpdateDescription(shift.id, currentExtraWorkPull.id, description, jwt);

                                const isSuccessUpdate = upValue && upDescription;

                                setIsError(isSuccessUpdate);
                                if (isSuccessUpdate)
                                    setCurrentExtraWorkPull(null);
                            }}
                            stateAction={'Сохранить'}
                        />
                        <Alert key='danger' variant={'danger'}>
                            Что-то пошло не так!
                        </Alert>
                    </React.Fragment>
                    : null
            }
            <button className={styles.buttonSaveDate} onClick={onCancel}>закрыть</button>
        </div>;
    }

    const ExtraWorkPullView = ({ work }) => {
        return <div key={work.id} className={styles.extra_work}>
            <span>
                {work.name}: {work.value}
            </span>
        </div>;
    }

    const CheckDateOnCopy = (one, two) => {

        let twoDate = two;
        let oneDate = one;

        if (two.length != one.length)
            twoDate = two.split('.')[0].slice(0, -3);

        return oneDate == twoDate;
    }

    useEffect(() => {
        async function DidMount() {
            setShifts(await getShiftsAll(currentDate, jwt));
        }

        DidMount();
    }, []);

    const Shift = ({ data, index }) => {

        const [shiftIndex] = useState(index);
        const [shift, setShift] = useState(data);
        const [isEdit, setIsEdit] = useState(false);

        return <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <div className={styles.shift}>
                <div className={styles.shiftHead}>
                    <label className={styles.titleDay}>{new Date(shift.start).toLocaleString(undefined, { day: 'numeric', month: 'long' })}
                        {new Date(shift.start).getDay() == new Date(shift.end).getDay() ? '' : ' - ' + new Date(shift.end).toLocaleString(undefined, { day: 'numeric', month: 'long' })}</label>
                    <MdEdit className={styles.shiftEdit} onClick={() => setIsEdit(!isEdit)} />
                </div>
                <div className={styles.shift_content}>
                    <div className={styles.textInfoShift}>Сотрудник: {shift.worker}</div>
                    <div className={styles.textInfoShift}>Время: {new Date(shift.start).toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' })} - {new Date(shift.end).toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' })}</div>
                    <div className={styles.textInfoShift}>Длительность: {TimeSpanParse(shift.timeSpan)}</div>
                    <div className={styles.textInfoShift}>Сумма баллов: {shift.sumPoints}</div>
                    <div className={styles.textInfoShift}>Простые баллы: {shift.simplePoints}</div>
                    <div className={styles.textInfoShift}>Средние баллы: {shift.middlePoints}</div>
                    <div className={styles.textInfoShift}>Сложные баллы: {shift.highPoints}</div>
                    <div className={styles.textInfoShift}>Повышенные баллы: {shift.upPoints}</div>
                    <div className={styles.dayOffContainer}>
                        <input className={styles.dayOffCheckBox} type="checkbox" onChange={async (e) => await shiftUpdateDayOff(shift.id, e.target.checked, jwt)} defaultChecked={shift.isDayOff} />
                        <div className={styles.textInfoShift}>Выходной</div>
                    </div>
                    <div className={styles.extra_works}>
                        {
                            shift.extraWorksPull.length > 0 ?
                                shift.extraWorksPull.map(work => <ExtraWorkPullView key={work.id} work={work} />)
                                : 'Работы отсутствуют'
                        }
                    </div>
                </div>
            </div>
            {isEdit ?
                <ShiftEditMode shift={shift} onCancel={() => setIsEdit(false)} />
                : null
            }
        </div>
    }

    const shiftsUI = shifts.map((shift, index) => <Shift key={shift.id} data={shift} index={index} />);

    return (
        <div className={styles.historyBody}>
            <div className={styles.panelMove}>
                <label className={styles.dateString}>{currentDate}</label>
                <input className={styles.dateInput} value={currentDate} onChange={async (e) => { setCurrentDate(e.target.value); setShifts(await getShiftsAll(e.target.value, jwt)); }} type='date' />
            </div>
            <div className={styles.content}>
                <div id="listContainer" className={styles.shift_container}>
                    {shiftsUI.length <= 0 ?
                        <div className={styles.emptyShift}>
                            Сегодня нет работ
                        </div> : shiftsUI}
                </div>
            </div>
        </div>
    );
}

export default ShiftEdit;